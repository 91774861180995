<template>
	<div class="mt-20 md:mt-0">
		<!--------------------------- SUBHEADER------------------------------------------------------->
		<div
			class="flex items-center justify-between mb-4 text-white bg-verdedark w-full fixed md:static"
		>
			<div class="text-white font-bold mt-2 ml-2 w-30 items-center">
				<span
					># Orden de Compra: {{ this.$route.params.id.padStart(4, "0") }}</span
				>
			</div>
			<div class="text-white font-bold mt-2 ml-2 items-center">
				<div>{{ firstLetterUpperCase(clienteNombre) }}</div>
				<div>{{ clienteTelefono }}</div>
			</div>
			<div class="flex items-center justify-center">
				<button
					@click="pedidoCancelado()"
					class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
				>
					<svg
						version="1.1"
						id="Capa_1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						x="0px"
						y="0px"
						viewBox="0 0 368.022 368.022"
						style="enable-background: new 0 0 368.022 368.022"
						class="h-6 w-6"
						xml:space="preserve"
					>
						<path
							style="fill: #d7443e"
							d="M314.101,54.133c71.79,71.847,71.744,188.287-0.103,260.077s-188.287,71.744-260.077-0.103 c-71.75-71.806-71.75-188.167,0-259.974c71.381-71.847,187.49-72.224,259.337-0.843C313.54,53.57,313.821,53.851,314.101,54.133z"
						/>
						<polygon
							style="fill: #ffffff"
							points="275.439,124.663 215.88,184.223 275.439,243.782 243.57,275.651 184.011,216.092 124.452,275.651 92.582,243.782 152.142,184.223 92.582,124.663 124.452,92.794 184.011,152.353 243.57,92.794"
						/>
					</svg>
					<span class="underline ml-2">Anular Orden de Compra</span>
				</button>
			</div>
		</div>
		<!------------------------------------BODY----------------------------------------------------->
		<div class="mx-auto my-4 mt-20 md:mt-0">
			<div class="md:flex justify-between w-auto mx-10">
				<div class="flex items-center">
					<p class="flex">Vendedor:</p>
					<div v-if="!mostrarSelectVendedores">
						<div
							class="ml-2 bg-secund flex py-1 px-2 w-64 border border-secundB rounded-md focus:outline-none"
						>
							{{ firstLetterUpperCase(compraIdTotal.vendedor.user.name) }}
						</div>
					</div>
					<div v-else>
						<select
							class="ml-2 bg-secund flex py-1 px-2 w-64 border border-secundB rounded-md focus:outline-none"
							name="local"
							id=""
							v-model="compraIdTotal.vendedor_id"
							@change="cambioVendedor()"
						>
							<option
								v-for="(vendedor, k) in vendedoresActivos"
								:key="k"
								:value="vendedor.id"
							>
								{{ firstLetterUpperCase(vendedor.user.name) }}
							</option>
						</select>
					</div>
				</div>
				<div class="flex items-center">
					<p class="md:ml-10 mr-3">Tasa Dia Compra Bs:</p>
					<input
						type="text"
						disabled
						class="border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold py-1 px-2 inline-flex items-center focus:outline-none"
						v-model="tasaDiaCompra"
					/>
				</div>
				<div class="md:ml-2 flex items-center">
					<p class="flex">Estatus:</p>
					<div
						class="ml-2 bg-secund flex py-1 px-2 w-64 bg-white border border-secundB rounded-md focus:outline-none"
					>
						{{ firstLetterUpperCase(compraIdTotal.estatus) }}
					</div>
				</div>
			</div>
			<div class="mx-10 lg:mx-0 md:mx-12 my-4 md:flex items-center">
				<div v-if="compraIdTotal.estatus != 'anulado'" class="md:mx-12 my-4">
					<div
						v-if="compraIdTotal.cobrado === '0'"
						class="md:ml-2 lg:mx-0 flex items-center"
					>
						<p class="flex">Cobrado:</p>
						<select
							class="md:ml-2 bg-secund flex py-1 px-2 w-64 bg-white border border-secundB rounded-md focus:outline-none"
							name="local"
							id=""
							v-model="compraIdTotal.cobrado"
							@change="pedidoCobrado()"
						>
							<option value="1">Cobrado</option>
							<option value="0">No cobrado</option>
						</select>
					</div>
					<div v-else class="md:ml-2 lg:mx-0 flex items-center">
						<p class="flex">Cobrado:</p>
						<div
							class="ml-2 bg-secund flex py-1 px-2 w-64 bg-white border border-secundB rounded-md focus:outline-none"
						>
							Cobrado
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="showPDF" class="flex justify-center mb-5">
			<div>
				<input
					class="mx-3"
					type="radio"
					name="TipoPdf"
					id="aumento"
					value="Bolivares"
					v-model="tipoPDF"
				/>
				<span> Mostrar monto en Bs al cliente</span>
			</div>
			<div>
				<input
					class="mx-3"
					type="radio"
					name="TipoPdf"
					id="aumento"
					value="Dolares"
					v-model="tipoPDF"
				/>
				<span> Mostrar monto en $ al cliente</span>
			</div>
			<div>
				<input
					class="mx-3"
					type="radio"
					name="TipoPdf"
					id="aumento"
					value="DolaresBolivares"
					v-model="tipoPDF"
				/>
				<span> Mostrar monto en Bs y $ al cliente</span>
			</div>
		</div>

		<div v-if="showPDF" class="flex items-center justify-center w-full my-10">
			<Botons
				@click="generarPDF()"
				class="underline mr-10"
				botonsText="Generar PDF"
			>
			</Botons>
		</div>
		<div class="mx-auto mb-10 flex items-center justify-center ml-72 md:ml-0">
			<div>
				<table class="table-auto">
					<thead>
						<tr>
							<td class="border-2 px-4 py-2 font-bold">Producto</td>
							<td class="border-2 px-4 py-2 font-bold">Cantidad</td>
							<td class="border-2 px-4 py-2 font-bold">Unidad</td>
							<td class="border-2 px-4 py-2 font-bold">Precio unitario Bs</td>
							<td class="border-2 px-4 py-2 font-bold">Precio unitario $</td>
							<td class="border-2 px-4 py-2 font-bold">Monto a cobrar Bs</td>
							<td class="border-2 px-4 py-2 font-bold">Monto a cobrar $</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(producto, k) in listaProductos" :key="k">
							<td class="border-2 px-4 py-2">
								{{ firstLetterUpperCase(producto.Producto) }}
							</td>
							<td class="border-2 px-4 py-2 text-center">
								{{ formato(producto.Cantidad) }}
							</td>
							<td class="border-2 px-4 py-2 text-center">
								{{ firstLetterUpperCase(producto.Unidad) }}
							</td>
							<td class="border-2 px-4 py-2 text-center">
								{{
									formato(
										parseFloat(compraIdTotal.tasa_dia) *
											parseFloat(producto.PrecioUnitarioD)
									)
								}}
							</td>
							<td class="border-2 px-4 py-2 text-center">
								{{ formato(producto.PrecioUnitarioD) }}
							</td>
							<td class="border-2 px-4 py-2 text-center">
								{{
									formato(
										parseFloat(compraIdTotal.tasa_dia) *
											parseFloat(producto.PrecioUnitarioD) *
											parseFloat(producto.Cantidad)
									)
								}}
							</td>
							<td class="border-2 px-4 py-2 text-center">
								{{
									formato(
										parseFloat(producto.Cantidad) *
											parseFloat(producto.PrecioUnitarioD)
									)
								}}
							</td>
						</tr>
						<tr v-if="showPDF">
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td class="text-center border-2 px-4 py-4">Exento</td>
							<td class="text-center border-2 px-4 py-4">
								{{
									formato(
										parseFloat(objetoDatosFactura[0].exento) *
											parseFloat(compraIdTotal.tasa_dia)
									)
								}}
							</td>
							<td class="text-center border-2 px-4 py-4">
								{{ formato(parseFloat(objetoDatosFactura[0].exento)) }}
							</td>
						</tr>
						<tr v-if="showPDF">
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td class="text-center border-2 px-4 py-4">Subtotal</td>
							<td class="text-center border-2 px-4 py-4">
								{{
									formato(
										parseFloat(objetoDatosFactura[0].subtotal) *
											parseFloat(compraIdTotal.tasa_dia)
									)
								}}
							</td>
							<td class="text-center border-2 px-4 py-4">
								{{ formato(parseFloat(objetoDatosFactura[0].subtotal)) }}
							</td>
						</tr>
						<tr v-if="showPDF">
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td class="text-center border-2 px-4 py-4">Base Imponible</td>
							<td class="text-center border-2 px-4 py-4">
								{{
									formato(
										(parseFloat(objetoDatosFactura[0].subtotal) +
											parseFloat(objetoDatosFactura[0].exento)) *
											parseFloat(compraIdTotal.tasa_dia)
									)
								}}
							</td>
							<td class="text-center border-2 px-4 py-4">
								{{
									formato(
										parseFloat(objetoDatosFactura[0].subtotal) +
											parseFloat(objetoDatosFactura[0].exento)
									)
								}}
							</td>
						</tr>
						<tr v-if="showPDF">
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td class="text-center border-2 px-4 py-4">IVA</td>
							<td class="text-center border-2 px-4 py-4">
								{{
									formato(
										parseFloat(objetoDatosFactura[0].ivaTotal) *
											parseFloat(compraIdTotal.tasa_dia)
									)
								}}
							</td>
							<td class="text-center border-2 px-4 py-4">
								{{ formato(parseFloat(objetoDatosFactura[0].ivaTotal)) }}
							</td>
						</tr>
						<tr v-if="showPDF">
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td class="text-center border-2 px-4 py-4">Total</td>
							<td class="text-center border-2 px-4 py-4">
								{{
									formato(
										(parseFloat(objetoDatosFactura[0].exento) +
											parseFloat(objetoDatosFactura[0].subtotal) +
											parseFloat(objetoDatosFactura[0].ivaTotal)) *
											compraIdTotal.tasa_dia
									)
								}}
							</td>
							<td class="text-center border-2 px-4 py-4">
								{{
									formato(
										parseFloat(objetoDatosFactura[0].exento) +
											parseFloat(objetoDatosFactura[0].subtotal) +
											parseFloat(objetoDatosFactura[0].ivaTotal)
									)
								}}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div
			v-if="compraIdTotal.estatus == 'entregado'"
			class="my-10 flex justify-around"
		>
			<boton-primario
				botonpText="Recalcular Orden Compra"
				@click="actualizarOrdenCompra"
			/>
			<router-link
				v-if="$store.state.role === 'superAdmin'"
				:to="{
					name: 'EditPedidoID',
					params: {
						id: $route.params.id
					}
				}"
				><boton-primario botonpText="Editar Orden Compra"
			/></router-link>
		</div>
	</div>
</template>

<script>
import gql from "graphql-tag";
import Botons from "@/components/botonSecundario";
import { formato, firstLetterUpperCase, formatoFecha } from "@/functions.js";
import BotonPrimario from "../components/botonPrimario.vue";

export default {
	components: {
		Botons,
		BotonPrimario
	},
	data() {
		return {
			estatusOpcionesEspera: [
				{ estado: "Nuevo", valor: "nuevo" },
				{ estado: "Asignado", valor: "asignado" },
				{ estado: "Reasignado", valor: "reasignado" },
				{ estado: "Espera", valor: "espera" },
				{ estado: "Ejecucion", valor: "ejecucion" },
				{ estado: "Finalizado", valor: "finalizado" },
				{ estado: "Entregado", valor: "entregado" },
				{ estado: "Anulado", valor: "anulado" }
			],
			estatusOpcionesEjecucion: [
				{ estado: "Reasignado", valor: "reasignado" },
				{ estado: "Ejecucion", valor: "ejecucion" },
				{ estado: "Finalizado", valor: "finalizado" },
				{ estado: "Entregado", valor: "entregado" },
				{ estado: "Anulado", valor: "anulado" }
			],
			tasaDiaCompra: "",
			compraIdTotal: [],
			listaProductos: [],
			tipoPDF: "Bolivares",
			mostrarSelectVendedores: true,
			showPDF: false,
			objetoDatosFactura: [],
			vendedoresActivos: [],
			clienteNombre: "",
			clienteTelefeno: ""
		};
	},
	async mounted() {
		this.$store.state.isLoading = true;
		await this.dataOrdenCompra();
		this.$store.state.isLoading = false;
	},
	methods: {
		async listaVendedoresActivos() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/vendedoresActivos.gql"),
					variables: {
						disponible: "1",
						id: 1
					}
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.vendedoresActivos = data.data.vendedoresActivos;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},

		firstLetterUpperCase,
		formato,

		//solo se ejecuta en caso de que exista un error en la carga de la orden de compra al momento de ser entregado, completado o finalizado el pedido.

		async actualizarOrdenCompra() {
			this.listaProductos = [];
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/actualizarOrdenCompra.gql"),
					variables: {
						compras_id: parseInt(this.$route.params.id)
					},
					fetchPolicy: "no-cache"
				})
				.then(() => {
					this.dataOrdenCompra();
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.error(error);
				});
		},
		async dataOrdenCompra() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: gql`
						query compraIdTotal($id: ID!) {
							compraIdTotal(id: $id) {
								estatus
								vendedor_id
								vendedor {
									user {
										name
									}
								}
								pedido {
									cotizacion_id
								}
								utilidades
								lista_productos
								tasa_dia
								pago_vendedor
								cobrado
								fecha_entrega
								user {
									name
									address
									numero_telefono_movil
								}
							}
						}
					`,
					variables: {
						id: parseInt(this.$route.params.id)
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.compraIdTotal = data.data.compraIdTotal[0];
					this.tasaDiaCompra = formato(data.data.compraIdTotal[0].tasa_dia);
					this.clienteNombre = data.data.compraIdTotal[0].user.name;
					this.clienteTelefono =
						data.data.compraIdTotal[0].user.numero_telefono_movil;
					//Descompongo el Json
					let listaRaw = JSON.parse(this.compraIdTotal.lista_productos);
					//Guardo Json sin la ultima posición
					for (let i = 0; i < listaRaw.length; i++) {
						//condicional para llenar objeto de los items comprados
						if (listaRaw[i].PrecioUnitarioD) {
							if (parseFloat(listaRaw[i].Cantidad) > 0) {
								this.listaProductos.push(listaRaw[i]);
							}
						} else {
							//Datos del total iva, exento y total
							this.objetoDatosFactura.push(listaRaw[i]);
							this.showPDF = true;
						}
					}
					if (
						this.compraIdTotal.estatus === "finalizado" ||
						this.compraIdTotal.estatus === "entregado" ||
						this.estatus === "completado"
					) {
						if (this.objetoDatosFactura.length < 1) {
							this.showPDF = false;
							this.actualizarOrdenCompra();
						} else {
							this.showPDF = true;
						}
					}
					//Condicional para habilitar o no el cambio de vendedor
					if (
						this.compraIdTotal.estatus === "finalizado" ||
						this.compraIdTotal.estatus === "entregado" ||
						this.compraIdTotal.estatus === "anulado"
					) {
						this.mostrarSelectVendedores = false;
					}

					if (
						(this.compraIdTotal.estatus === "asignado" ||
							this.compraIdTotal.estatus === "espera") &&
						listaRaw.length < 1
					) {
						this.recalcularOrdenCompra();
					}
					this.listaVendedoresActivos();
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		async recalcularOrdenCompra() {
			this.$store.state.isLoading = true;
			this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/generarJsonOrdenCompra.gql"),
					variables: {
						compra_id: parseInt(this.$route.params.id)
					}
				})
				.then(() => {
					this.dataOrdenCompra();
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		pagoVendedor() {
			this.$store.state.isLoading = true;
			this.$apollo
				.mutate({
					mutation: gql`
						mutation($id: ID!, $data: UpdateEstatusCompra!) {
							editarEstadoPedidoVendedor(id: $id, input: $data) {
								id
								pago_vendedor
							}
						}
					`,
					variables: {
						id: this.$route.params.id,
						data: {
							pago_vendedor: this.compraIdTotal.pago_vendedor
						}
					}
				})
				.then(() => {
					this.$store.state.isLoading = false;
					alert(
						"Pago a vendedor por orden de compra # " +
							this.$route.params.id +
							" actualizado"
					);
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		pedidoCobrado() {
			this.$store.state.isLoading = true;
			this.$apollo
				.mutate({
					mutation: gql`
						mutation($id: ID!, $data: UpdateEstatusCompra!) {
							editarEstadoPedidoVendedor(id: $id, input: $data) {
								id
								cobrado
							}
						}
					`,
					variables: {
						id: this.$route.params.id,
						data: {
							cobrado: this.compraIdTotal.cobrado
						}
					}
				})
				.then(() => {
					this.$store.state.isLoading = false;
					alert("Orden de compra # " + this.$route.params.id + " cobrada");
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		pedidoCancelado() {
			let confirmar = false;
			var opcion = confirm("¿Esta seguro de anular la orden de compra?");
			let objetoEntrada = {};

			if (opcion == true) {
				confirmar = true;
			} else {
				confirmar = false;
			}
			if (confirmar === true) {
				//Si el pedido esta en ejecucion no se paga la comision
				if (this.compraIdTotal.estatus === "ejecucion") {
					objetoEntrada = {
						estatus: "anulado",
						comision_vendedor: "0"
					};
				} else {
					objetoEntrada = {
						estatus: "anulado"
					};
				}
				this.$store.state.isLoading = true;
				this.$apollo
					.mutate({
						mutation: gql`
							mutation($id: ID!, $data: UpdateEstatusCompra!) {
								editarEstadoPedidoVendedor(id: $id, input: $data) {
									id
									estatus
								}
							}
						`,
						variables: {
							id: this.$route.params.id,
							data: objetoEntrada
						}
					})
					.then(data => {
						this.$store.state.isLoading = false;
						this.compraIdTotal.estatus =
							data.data.editarEstadoPedidoVendedor.estatus;
					})
					.catch(error => {
						this.$store.state.isLoading = false;
						console.log(error);
					});
			}
		},
		cambioVendedor() {
			let confirmarRechazo = false;
			let opcion = confirm(
				"¿Esta seguro del cambio de vendedor para la orden de compra?"
			);
			if (opcion) {
				confirmarRechazo = true;
			} else {
				confirmarRechazo = false;
			}
			if (confirmarRechazo) {
				this.$store.state.isLoading = true;
				this.$apollo
					.mutate({
						mutation: gql`
							mutation($data: UpdateEstatusCompra!) {
								cambioVendedor(data: $data) {
									id
								}
							}
						`,
						variables: {
							data: {
								id: this.$route.params.id,
								vendedor_id: this.compraIdTotal.vendedor_id
							}
						}
					})
					.then(() => {
						this.$store.state.isLoading = false;
						alert(
							"Vendedor cambiado para la orden de compra # " +
								this.$route.params.id
						);
					})
					.catch(error => {
						this.$store.state.isLoading = false;
						console.log(error);
					});
			}
		},
		generarPDF() {
			let direccion = "";
			let dataPDF = {
				nombre: "",
				direccion: "",
				telefono: "",
				cotizacion: "",
				fecha: "",
				subtotalbs: "",
				subtotalusd: "",
				ivabs: "",
				ivad: "",
				totalbs: "",
				totalusd: "",
				exentoBs: "",
				exentoUsd: "",
				pedido: []
			};
			//Saca la fecha del pedido
			let today = new Date(
				this.compraIdTotal.fecha_entrega.replaceAll("-", "/")
			);
			let dd = String(today.getDate()).padStart(2, "0");
			let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
			let yyyy = today.getFullYear();
			console.log("today", today);
			today = dd + "/" + mm + "/" + yyyy;
			//Data general a llenar
			dataPDF.nombre = this.compraIdTotal.user.name;
			dataPDF.direccion = this.compraIdTotal.user.address;
			dataPDF.telefono = this.compraIdTotal.user.numero_telefono_movil;
			dataPDF.cotizacion = this.compraIdTotal.pedido[0].cotizacion_id.padStart(
				4,
				"0"
			);
			dataPDF.fecha = today;
			//Datos exentos, subtotales, iva y totales

			dataPDF.subtotalbs =
				parseFloat(this.objetoDatosFactura[0].subtotal) *
				parseFloat(this.compraIdTotal.tasa_dia);
			dataPDF.subtotalusd = parseFloat(this.objetoDatosFactura[0].subtotal);
			dataPDF.ivabs =
				parseFloat(this.objetoDatosFactura[0].ivaTotal) *
				parseFloat(this.compraIdTotal.tasa_dia);
			dataPDF.ivad = parseFloat(this.objetoDatosFactura[0].ivaTotal);
			dataPDF.exentoBs =
				parseFloat(this.objetoDatosFactura[0].exento) *
				parseFloat(this.compraIdTotal.tasa_dia);
			dataPDF.exentoUsd = parseFloat(this.objetoDatosFactura[0].exento);
			dataPDF.totalbs = dataPDF.subtotalbs + dataPDF.ivabs + dataPDF.exentoBs;
			dataPDF.totalusd = dataPDF.subtotalusd + dataPDF.ivad + dataPDF.exentoUsd;

			//llenar data pdf verifica si el monto se muestra en $
			for (let index = 0; index < this.listaProductos.length; index++) {
				if (this.tipoPDF === "DolaresBolivares") {
					dataPDF.pedido.push({
						Cantidad: this.listaProductos[index].Cantidad,

						Unidad: this.firstLetterUpperCase(
							this.listaProductos[index].Unidad
						),

						PrecioUnitarioBs:
							parseFloat(this.listaProductos[index].PrecioUnitarioD) *
							parseFloat(this.compraIdTotal.tasa_dia),

						PrecioUnitarioD: parseFloat(
							this.listaProductos[index].PrecioUnitarioD
						),

						MontoCobrarBs:
							parseFloat(this.listaProductos[index].PrecioUnitarioD) *
							parseFloat(this.listaProductos[index].Cantidad) *
							parseFloat(this.compraIdTotal.tasa_dia),
						MontoCobrarD:
							parseFloat(this.listaProductos[index].PrecioUnitarioD) *
							parseFloat(this.listaProductos[index].Cantidad),
						Producto: this.firstLetterUpperCase(
							this.listaProductos[index].Producto.slice(0, 50)
						)
					});
				} else if (this.tipoPDF === "Bolivares") {
					dataPDF.pedido.push({
						Cantidad: this.listaProductos[index].Cantidad,
						Unidad: this.firstLetterUpperCase(
							this.listaProductos[index].Unidad
						),
						PrecioUnitarioBs:
							parseFloat(this.listaProductos[index].PrecioUnitarioD) *
							parseFloat(this.compraIdTotal.tasa_dia),

						MontoCobrarBs:
							parseFloat(this.listaProductos[index].PrecioUnitarioD) *
							parseFloat(this.listaProductos[index].Cantidad) *
							parseFloat(this.compraIdTotal.tasa_dia),

						Producto: this.firstLetterUpperCase(
							this.listaProductos[index].Producto.slice(0, 50)
						)
					});
				} else {
					dataPDF.pedido.push({
						Cantidad: this.listaProductos[index].Cantidad,
						Unidad: this.firstLetterUpperCase(
							this.listaProductos[index].Unidad
						),
						PrecioUnitarioD: parseFloat(
							this.listaProductos[index].PrecioUnitarioD
						),

						MontoCobrarD:
							parseFloat(this.listaProductos[index].PrecioUnitarioD) *
							parseFloat(this.listaProductos[index].Cantidad),
						Producto: this.firstLetterUpperCase(
							this.listaProductos[index].Producto.slice(0, 50)
						)
					});
				}
			}
			// Paso variables al store
			this.$store.commit("crearDataPdf", JSON.parse(JSON.stringify(dataPDF)));
			// Me voy a otra ruta dependiendo de si esta marcado mostrarDolar o no
			let routeData;
			if (this.tipoPDF === "DolaresBolivares") {
				routeData = this.$router.resolve({
					name: "PdfUsdBs"
				});
			} else if (this.tipoPDF === "Bolivares") {
				routeData = this.$router.resolve({
					name: "PdfBs"
				});
			} else {
				routeData = this.$router.resolve({
					name: "PdfUsd"
				});
			}
			window.open(routeData.href, "_blank");
			this.mostrarPDF = false;
		}
	}
};
</script>
